<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div v-if="locationData">
      <figure class="image is-5by3 has-background-grey-light has-margin-bottom">
        <img
          src="@/assets/img/spacer5_3.gif"
          :style="{backgroundImage: 'url(' + $options.filters.getLocationImage(locationData.Image, locationData.Id, 640) + ')' }"
        >
      </figure>
      <p
        v-if="!hideName"
        class="locationTitle title is-5 no-margin-bottom"
      >{{locationData.Name}}</p>
      <p
        style="margin-bottom: 5px;"
        v-if="!hideAddress"
      >{{ locationData.Address }}, {{ locationData.City }}</p>
      <p v-if="!hideTime">
        <i class="far fa-clock has-margin-right-half"></i>
        <span>{{ openingHours.MinMinutes | minutesToTime }}</span>
        <span>-</span>
        <span>{{ openingHours.MaxMinutes | minutesToTime }}</span>
      </p>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    hideName: {
      type: Boolean,
      default: false
    },
    hideAddress: {
      type: Boolean,
      default: false
    },
    hideTime: {
      type: Boolean,
      default: false
    },
    locationData: {
      type: Object,
      required: true
    },
    openingHours: {
      type: Object,
      default: function() {
        return {
          NrOfLocations: 0,
          MinTimeOpenTimeStamp: 0,
          MaxTimeCloseTimeStamp: 0,
          MaxTimeClose: new Date().toISOString(),
          MinTimeOpen: new Date().toISOString(),
          MaxMinutes: 0,
          MinMinutes: 0
        }
      }
    }
  }
}
</script>
